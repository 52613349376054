import axios from '@axios'

export default {
  namespaced: true,
  state: {
    inpoUserDetails: null,
  },
  getters: {},
  mutations: {
    GET_USER_INPO(state, inpo) {
      state.inpoUserDetails = inpo
    },
  },
  actions: {
    fetchINPO(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://gateway.muslimdo.com/api/auth/entities/list', queryParams, {
            headers: {
              token: localStorage.getItem('access_token'),
            },
          })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    fetchINPODetail({ commit }, { id }) {
      axios.get(`http://entities-microsevice.muslimdo.com/api/internalops/entity/${id}`).then(res => {
        commit('GET_USER_INPO', res.data.data)
      })
    },
  },
}
