import axios from 'axios'

const Api = axios.create({
  baseURL: 'https://gateway.muslimdo.com/api/auth/entities',
  headers: {
    token: localStorage.getItem('access_token'),
  },
})

export default Api
