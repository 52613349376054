<template>

  <div>
    <!-- <b-card>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Tags</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="roleFilter"
              :options="roleOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:roleFilter', val)"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Plan</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="planFilter"
              :options="planOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:planFilter', val)"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="statusFilter"
              :options="statusOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:statusFilter', val)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card> -->
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <organizaton-list-edit
        :id="selectedId"
        :show-edit="showEdit"
      />
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                :to="{ name: 'add-inpo' }"
                variant="primary"
              >
                <span class="text-nowrap">Add INPO</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="orgtable"
        :items="fetchData"
        :busy.sync="isBusy"
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        class="position-relative"
        responsive
        empty-text="No matching records found"
        @filtered="onFiltered"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.logo"
                :text="avatarText(data.item.name)"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">#{{ data.item.id }}</small>
          </b-media>
        </template>

        <!-- Column: Phone -->
        <template #cell(phone)="data">
          {{ data.item.phone_number }}
        </template>

        <!-- Column: City -->
        <template #cell(city)="data">
          <span
            v-for="(value, index) in data.item.address"
            :key="index"
          >{{ value.city }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'view-organization', params: { id: data.item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50"> View </span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'inpo-edit', params: { id: data.item.id}}">
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteNGO(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span
                class="align-middle ml-50"
              >Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div
        v-if="loading"
        class="loader"
      />
    </b-card>
    <template>
      <b-row>
        <b-col md="6">
          <b-pagination
            :value="1"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-0 mt-1"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="org-table"
            @input="currentPage = $event"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive,
} from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import { mapActions, mapState } from 'vuex'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBToggle, BCardHeader,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import Inpos from '@/apis/Inpo'
import OrganizatonListEdit from './OrganizatonListEdit.vue'
import inpoStoreModule from '../InpoStoreModule'
import inpodata from './inpos'
// eslint-disable-next-line import/no-extraneous-dependencies
Vue.use(VueSweetalert2)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    OrganizatonListEdit,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  // data() {
  //   return {
  //     filters: {
  //       search: null,
  //     },
  //   }
  // },
  // watch: {
  //   filters: {
  //     handler() {
  //       this.$refs.table.refresh()
  //     },
  //     deep: true,
  //   },
  // },
  setup() {
    const tableColumns = [
      { key: 'name', sortable: true, searchable: true },
      { key: 'email', sortable: true, searchable: true },
      { key: 'phone', sortable: true },
      { key: 'city', sortable: true },
      { key: 'actions' },
    ]
    const {
      rows,
      isBusy,
      totalRows,
      currentPage,
      perPage,
      perPageOptions,
      searchQuery,
      selectedId,
      showEdit,
      loading,
      filter,
      items,
      filterOn,
      fetchData,
      filters,
      orgtable,
      refetchData,
    } = inpodata()

    const INPO_MODULE_NAME = 'orgmodule'

    // Register module
    if (!store.hasModule(INPO_MODULE_NAME)) store.registerModule(INPO_MODULE_NAME, inpoStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INPO_MODULE_NAME)) store.unregisterModule(INPO_MODULE_NAME)
    })

    return {
      // Filter
      avatarText,
      tableColumns,
      rows,
      isBusy,
      totalRows,
      currentPage,
      perPage,
      perPageOptions,
      searchQuery,
      selectedId,
      showEdit,
      loading,
      filter,
      items,
      filterOn,
      fetchData,
      filters,
      orgtable,
      refetchData,
    }
  },
  created() {
  },
  mounted() {
    // this.$store.dispatch('inpo/provider')
  },
  methods: {
    // myProvider() {
    //   this.loading = true
    //   this.isBusy = true
    //   const data = {
    //     direction: 'search',
    //     type: 'Organization',
    //     perPage: this.perPage,
    //     pageNumber: this.currentPage,
    //     search_str: this.filters.search,
    //   }
    //   const headers = {
    //     token: localStorage.getItem('access_token'),
    //   }
    //   const promis = axios.post('https://gateway.muslimdo.com/api/auth/entities/list', data, {
    //     headers,
    //   })
    //   return promis.then(res => {
    //     const items = res.data.data
    //     console.log(res.data.data)
    //     this.items = res.data.data
    //     this.totalRows = res.data.pagination.total
    //     this.isBusy = false
    //     return items
    //   }).finally(() => {
    //     this.loading = false
    //   // eslint-disable-next-line no-unused-vars
    //   }).catch(error => [])
    // },
    deleteNGO(id) {
      // eslint-disable-next-line no-unused-expressions
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00A5AF',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`https://entities-microsevice.muslimdo.com/api/internalops/entity/${id}`).then(res => {
            this.$swal(
              'Deleted!',
              'Organization has been deleted.',
              'success',
            )
            this.$refs.orgtable.refresh()
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
