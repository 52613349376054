<template>
  <div>
    <b-sidebar
      id="sidebar-right-edit"
      ref="mySidebar"
      sidebar-class="sidebar-lg"
      title="Edit Organization"
      backdrop
      no-close-on-backdrop
      no-header-close
      right
      shadow
      @click="showEdit"
    >
      <template>
        <!-- BODY -->
        <validation-observer
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.enter.prevent=""
            @reset.prevent="resetForm"
          >
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="formData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Mobile Number -->
            <validation-provider
              #default="validationContext"
              name="Mobile Number"
              rules="required"
            >
              <b-form-group
                label="Mobile Number"
                label-for="mobile-number"
              >
                <b-form-input
                  id="mobile-number"
                  v-model="formData.phone_number"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="formData.email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Classification -->
            <validation-provider
              #default="validationContext"
              name="Classification"
              rules="required"
            >
              <b-form-group
                label="Classification"
                label-for="classification"
                :state="getValidationState(validationContext)"
              >
                <b-form-select
                  v-model="formData.classification_id"
                  :options="classificationOptions"
                  class="mb-3"
                  value-field="id"
                  text-field="name"
                >
                  <template #first>
                    <b-form-select-option
                      disabled
                    >
                      {{ formData.classification }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- About -->
            <validation-provider
              #default="validationContext"
              name="About"
              rules="required"
            >
              <b-form-group
                label="About"
                label-for="about"
              >
                <b-form-textarea
                  id="about"
                  v-model="formData.about"
                  :state="getValidationState(validationContext)"
                  placeholder="About"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="updateNGO()"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="cancel()"
              >
                Cancel
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormFile, BFormSelect, BFormSelectOption,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { BoldIcon } from 'vue-feather-icons'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'

Vue.use(VueSweetalert2)

export default {
  components: {
    BSidebar,
    // eslint-disable-next-line vue/no-unused-components
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,

    // form valodation
    ValidationProvider,
    ValidationObserver,
    BFormSelectOption,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    hideShowEdit: {
      type: Function,
      default: () => 1,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      variant: 'dark',
      required,
      alphaNum,
      email,
      show: true,
      typeUrl: 'https://entities-microsevice.muslimdo.com/api/internalops/entity_types/list',
      classificationUrl: 'https://entities-microsevice.muslimdo.com/api/internalops/entity_classifications/list',
      typeOptions: [],
      classificationOptions: [],
      formData: {
        name: '',
        type_id: '1',
        classification_id: '',
        phone_number: '',
        email: '',
        about: '',
        id: this.id,
      },
    }
  },
  updated() {
    console.log('abed')
    this.getNGOId()
    this.getType()
    this.getClassification()
    // eslint-disable-next-line no-unused-expressions
    console.log(this.formData)
  },
  mounted() {
    // eslint-disable-next-line no-unused-expressions
    console.log(this.formData)
  },
  created() {
    console.log(this.formData)
  },
  methods: {
    cancel() {
      this.formData = {}
      this.$emit('hideShowEdit')
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
    },
    getType() {
      axios.get(this.typeUrl).then(res => {
        this.typeOptions = res.data.data.data
      })
    },
    getClassification() {
      axios.get(this.classificationUrl).then(res => {
        this.classificationOptions = res.data.data.data
      })
    },
    getNGOId() {
      axios.get(`https://entities-microsevice.muslimdo.com/api/internalops/entity/${this.id}`).then(res => {
        console.log(res.data)
        this.formData = res.data.data
        console.log(this.formData)
      }).catch(err => {
        console.log(err)
      })
    },
    updateNGO() {
      this.formData.type_id = 1
      this.formData.id = this.id
      axios.post(`https://entities-microsevice.muslimdo.com/api/internalops/entity/${this.id}?_method=put`, this.formData).then(res => {
        console.log(res.data.data)
        this.cancel()
        this.$swal(
          'Update!',
          'Organization has been updated.',
          'success',
        )
        this.$parent.$refs.table.refresh()
      }).catch(err => {
        console.log(err)
      })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.form-main {
    width: 79%;
    margin: auto;
}
</style>
