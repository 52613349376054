/* eslint-disable no-unused-vars */
import { ref, reactive, watch } from '@vue/composition-api'
import store from '@/store'

export default function prayers() {
  const orgtable = ref(null)
  const rows = ref([])
  const isBusy = ref(false)
  const totalRows = ref(1)
  const currentPage = ref(1)
  const perPage = ref(15)
  const perPageOptions = ref(['10', '20', '30'])
  const searchQuery = ref('')
  const selectedId = ref(0)
  const showEdit = ref(false)
  const loading = ref(false)
  const filter = ref(null)
  const items = ref([])
  const filterOn = ref([])

  const fetchData = (ctx, callback) => {
    loading.value = true
    store.dispatch('orgmodule/fetchINPO', {
      search_str: searchQuery.value,
      direction: 'search',
      type: 'Organization',
      perPage: perPage.value,
      pageNumber: currentPage.value,
    }).then(res => {
      totalRows.value = res.data.pagination.total
      callback(res.data.data)
    }).finally(() => {
      loading.value = false
    }).catch(err => console.log(err))
  }

  const refetchData = () => {
    orgtable.value.refresh()
  }

  watch([searchQuery], () => {
    refetchData()
  }, { deep: true })

  return {
    rows,
    isBusy,
    totalRows,
    currentPage,
    perPage,
    perPageOptions,
    searchQuery,
    selectedId,
    showEdit,
    loading,
    filter,
    items,
    filterOn,
    fetchData,
    orgtable,
    refetchData,
  }
}
